.modal-body {
  overflow: hidden;
}

.app-json {
  font-size: 10pt;
}

.modal-content {
  max-height: 85% !important;
}

.close-btn {
  background-color: #3f51b5 !important;
  outline: 0;
}
